<template>
    <div class="cloud-model" :class="'cloud-model--' + mod">
        <div class="cloud-model__type" :class="'cloud-model__type--' + mod"> {{ type }}</div>
        <img v-if="mod==='feautured'" class="cloud-model__logo" src="@/assets/images/logo--white.png" alt="Chmura SupportIT">
        <img v-else="mod==='feautured'" class="cloud-model__logo" src="@/assets/images/logo.png" alt="Chmura SupportIT">
        <ul class="cloud-model__list">
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.data" :mod="mod" name="Dane" />
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.app" :mod="mod" name="Aplikacje" />
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.db" :mod="mod" name="Bazy danych" />
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.os" :mod="mod" name="Systemy operacyjne" />
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.virtalization" :mod="mod" name="Wirtualizacja" />
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.hardware" :mod="mod" name="Serwery fizyczne" />
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.storage" :mod="mod" name="Storage" />
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.net" :mod="mod" name="Sieć" />
            <c-check-list-item class="cloud-model__item" :status="elementsInModel.servierRoom" :mod="mod" name="Serwerownia" />
        </ul>

    </div>
</template>

<script>
    import cCheckListItem from '@/components/c-check-list-item.vue';

    export default {
        name: 'c-cloud-model',
        props: {
            type: {
                type: String,
                required: true
            },
            mod: {
                type: String,
                default: null
            }
        },
        computed: {
            elementsInModel() {              
                var elements;
                switch (this.type) {
                    case 'IaaS':
                        elements = {
                            data: false,
                            app: false,
                            db: false,
                            os: true,
                            virtalization: true,
                            hardware: true,
                            storage: true,
                            net: true,
                            servierRoom: true
                            };
                        break;
                    case 'PaaS':
                        elements = {
                            data: false,
                            app: false,
                            db: true,
                            os: true,
                            virtalization: true,
                            hardware: true,
                            storage: true,
                            net: true,
                            servierRoom: true
                        };
                        break;
                    case 'SaaS':
                        elements = {
                            data: false,
                            app: true,
                            db: true,
                            os: true,
                            virtalization: true,
                            hardware: true,
                            storage: true,
                            net: true,
                            servierRoom: true
                        };
                        break;
                    default:
                        elements = {
                            data: false,
                            app: false,
                            db: false,
                            os: false,
                            virtalization: false,
                            hardware: false,
                            storage: false,
                            net: false,
                            servierRoom: false
                        };
                        break;
                }
                return elements;
            }
        },
        components: {
            cCheckListItem
        },
    }
</script>

<style lang="scss">
    .cloud-model {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        max-width: 600px;
        overflow: hidden;
        margin: 0 auto;
        padding: 40px;
        border-radius: 5px;
        border: 1px solid $border-color;
        box-shadow: $box-shadow;

        &--feautured {
            background-color: $primary-color;

            &__type {
                background-color: #fff;
                color: $primary-color;
            }
        }

        &__type {
            position: absolute;
            top: 30px;
            left: 0;
            box-sizing: border-box;
            min-width: 80px;
            padding: 10px 23px 11px 23px;
            border-radius: 0px 30px 30px 0px;
            color: #fff;
            font-weight: 500;
            text-transform: uppercase;
            background-color: $primary-color;

            &--feautured {
                background-color: #fff;
                color: $primary-color;
            }
        }

        &__logo {
            display: block;
            width: 225px;
            margin: 0 auto;
        }

        &__list {
            margin-top: 30px;
            padding: 0;
            list-style: none;
            border-top: 1px solid $border-color;
        }

        &__item {
            margin-top: 20px;
        }
    }
</style>