<template>
  <div class="app">
      <c-header class="app__header" />
      <c-main class="app__main" />
      <c-footer class="app__footer" />
  </div>
</template>

<script>
  import cHeader from './components/sections/c-header.vue';
  import cMain from './components/sections/c-main.vue';
  import cFooter from './components/sections/c-footer.vue';

  export default {
    name: 'App',
    components: {
      cHeader,
      cMain,
      cFooter
    }
  }
</script>

<style lang="scss">
  .app {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    &__main {
      flex: 1;
    }

    &__header,
    &__footer {
      flex: 0;
    }

  }
</style>
