<template>
    <main class="main">
        <c-container class="main__container">
            <img class="main__img" src="@/assets/images/logo.png" alt="Chmura SupportIT">
            <h1 class="main__title">Chmura dla Twojej firmy</h1>
            <c-columns class="main__cloud-models">
                <c-column>
                    <c-cloud-model type="IaaS"></c-cloud-model>
                </c-column>
                <c-column>
                    <c-cloud-model type="PaaS"></c-cloud-model>
                </c-column>
                <c-column>
                    <c-cloud-model type="SaaS" mod="feautured"></c-cloud-model>
                </c-column>
            </c-columns>
        </c-container>
    </main>
</template>

<script>
    import cColumns from '@/components/grid/c-columns.vue';
    import cColumn from '@/components/grid/c-column.vue';
    import cCloudModel from '@/components/c-cloud-model.vue';

    export default {
        name: 'c-main',
        components: {
            cColumns,
            cColumn,
            cCloudModel
        },
    }
</script>

<style lang="scss">
    .main {
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;

        &__container {
            overflow-x: hidden;
        }

        &__title {
            font-size: 2.5rem;
        }
        
        &__img {
            height: 150px;
        }
    }
</style>