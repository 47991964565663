<template>
    <header class="header">
        <c-top-bar class="header__top-bar" />
    </header>
</template>

<script>
    import cTopBar from '@/components/c-top-bar.vue';
    export default {
        name: 'c-header',
        components: {
            cTopBar
        }
    }
</script>

<style lang="scss">
    .header {
        box-shadow: $box-shadow;
    }
</style>