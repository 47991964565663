<template>
    <div class="columns__column">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'c-column'
    }
</script>