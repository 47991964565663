<template>
    <li class="check-list-item"  :class="'check-list-item--' + mod">
                <div class="check-list-item__name" :class="'check-list-item__name--' + mod">{{ name }}</div>
                <div v-if="mod=='feautured'" class="cloud-model__element-status">
                    <img v-if="status" class="check-list-item__icon" src="@/assets/images/icons/accept--white.svg" />
                    <img v-else class="check-list-item__icon" src="@/assets/images/icons/close--white.svg" />
                </div>
                <div v-else class="cloud-model__element-status">
                    <img v-if="status" class="check-list-item__icon" src="@/assets/images/icons/accept.svg" />
                    <img v-else class="check-list-item__icon" src="@/assets/images/icons/close.svg" />
                </div>
            </li>
</template>

<script>
    export default {
        name: 'c-check-list-item',
        props: {
            name: {
                type: String,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
            mod: {
                type: String,
                default: null
            }
        }
    }
</script>

<style lang="scss">
    .check-list-item {
        $font-size: 1em;


        display: flex;
        justify-content: space-between;

        &__name {
            font-size: $font-size;

            &--feautured {
                color: #fff;
            }
        }

        &__icon {
            height: $font-size;
        }
    }
</style>