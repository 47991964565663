<template>
    <div class="container">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'c-container'
    }
</script>

<style lang="scss">
    .container {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 10px;

    }
</style>