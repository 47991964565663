<template>
    <div class="columns">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'c-columns'
    }
</script>

<style lang="scss">
    .columns {
        box-sizing: border-box;
        display: block;

        @media #{$brakepoint-medium} {
            display: flex;
        }

        &__column {
            flex: 1;
            padding: 10px;
        }
    }
</style>