<template>
    <div class="top-bar">
        <c-container class="top-bar__container">
            <nav class="top-bar__left">
                <div class="top-bar__contact-item">
                    <i class="fi fi--email top-bar__icon"></i>
                    <a class="top-bar__link" href="mailto:info@supportit.com.pl" title="Napisz do nas">info@supportit.com.pl</a>
                </div>
                <span class="top-bar__separator">|</span>
                <div class="top-bar__contact-item">
                    <i class="fi fi--call top-bar__icon"></i>
                    <a class="top-bar__link" href="tel:516147919" title="Napisz do nas">516 147 919</a>
                </div>
                <span class="top-bar__separator">|</span>
                <div class="top-bar__contact-item">
                    <i class="fi fi--location top-bar__icon"></i>
                    <a class="top-bar__link">ul. Stanisława Zwierzchowskiego 5 Poznań</a>
                </div>
            </nav>
            <div class="top-bar__right">
                <a class="top-bar__link" href="https://www.facebook.com/SupportITPoznan/" target="_blank" title="Facebook">
                    <i class="fab fa-facebook-f top-bar__icon"></i>
                </a>
                <a class="top-bar__link" href="https://pl.linkedin.com/company/supportit-pl" target="_blank" title="LinkedIn">
                    <i class="fab fa-linkedin-in top-bar__icon"></i>
                </a>
                <a class="top-bar__link" href="https://www.instagram.com/supportit_pl/" target="_blank" title="Instagram">
                    <i class="fab fa-instagram top-bar__icon"></i>
                </a>
            </div>
        </c-container>
    </div>
</template>

<script>
    export default {
        name: 'c-top-bar',
    };
</script>

<style lang="scss">
    .top-bar {
        $color: #fff;
        
        padding: 15px 0;
        color: $color;
        font-size: 1.1rem;
        line-height: 1.5rem;
        box-shadow: $box-shadow;
        background-color: $primary-color;

        @media #{$brakepoint-large} {
                font-size: 0.8rem;
            }

        &__container {
            justify-content: space-between;
            @media #{$brakepoint-large} {
                display: flex;   
            }
        }


        &__left {
            @media #{$brakepoint-medium} {
                display: flex;
            }
        }

        &__right {
            margin-top: 10px;
            display: flex;
            align-items: center;

            @media #{$brakepoint-large} {
                margin-top: 0;
            }
        }

        &__contact-item:not(:first-child) {
            margin-top: 10px;

            @media #{$brakepoint-medium} {
                margin-top: 0;
            }
        }
        &__link {
            color: $color;
            &:hover {
                color: darken($color, 10%);
            }
        }
        
        &__icon {
            margin: 0 8px;
            font-size: 1.5rem;
            vertical-align: bottom;

            @media #{$brakepoint-large} {
                font-size: 1.1rem;
            }
        }

        &__separator {
            display: none;
            font-size: 1.3rem;
            margin: 0 10px;
            @media #{$brakepoint-medium} {
                font-size: 1.1rem;
                display: unset;
            }
        }
    }
</style>