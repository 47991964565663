<template>
    <nav class="menu">
        <ul class="menu__list">
            <li class="menu__item">
                <a class="menu__link" href="https://supportit.com.pl/">Strona internetowa</a>
            </li>
            <li class="menu__item">
                <a class="menu__link" href="https://help.supportit.com.pl/">System helpdesk</a>
            </li>
            <li class="menu__item">
                <a class="menu__link" href="https://supportit.com.pl/polityka-prywatnosci">Polityka prywatności</a>
            </li>
            <li class="menu__item">
                <a class="menu__link" href="https://supportit.com.pl/kontakt">Kontakt</a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'c-menu',
    };
</script>

<style lang="scss">
    .menu {
        background-color: #f5f6f9;
        
        &__list {
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;

            @media #{$brakepoint-medium} {
                display: flex;
            }
        }
        
        &__item {
            font-size: 1.1rem;

            &:not(:first-child) {
                margin-top: 10px;

                @media #{$brakepoint-medium} {
                    margin-top: 0;
                }
            }

            &::before {
                content: '•';
                margin-right: 10px;
                color: $primary-color;

                @media #{$brakepoint-medium} {
                    margin-left: 10px;
                }
            }

            &:first-child::before {
                @media #{$brakepoint-medium} {
                    display: none;
                }
            }
        }

        &__link {
            color: $footer-color;
        }
    }
</style>