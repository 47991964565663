<template>
    <footer class="footer">
        <c-container class="footer__container">
            <div class="footer__copyright">
                Infrastruktura SupportIT © {{ year }} | Wszelkie prawa zastrzeżone
            </div>
            <c-menu class="footer__menu" />
        </c-container>
    </footer>
</template>

<script>
    import cMenu from '@/components/c-menu.vue';
    export default {
        name: 'c-footer',
        data () {
            return {
                year: new Date().getFullYear(),
            }
        },
        components: {
            cMenu,
        }
    };
</script>

<style lang="scss">
    .footer {
        padding: 20px 0;
        color: $footer-color;
        box-shadow: $box-shadow;
        background-color: #f5f6f9;

        &__container {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;

            @media #{$brakepoint-large} {
                flex-direction: row;
            }
        }

        &__copyright {
            margin-top: 20px;

            @media #{$brakepoint-large} {
                margin-top: 0;
            }
        }
    }
</style>